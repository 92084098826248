<template>
    <div id="consumerCoupon">
      <el-row style="background-color: #ffffff">
        <content-title :nav="nav"></content-title>
      </el-row>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="核销记录" name="0">
          <settleList></settleList>
        </el-tab-pane>
        <el-tab-pane label="课程核销" name="1">
          <settleManage></settleManage>
        </el-tab-pane>
      </el-tabs>
  
    </div>
  </template>
  
  <script>
  import settleList from "./components/settleList.vue";
  import settleManage from "./components/settleManage.vue";
  export default {
    components: {
        settleList,
      settleManage
    },
    data() {
      return {
        nav: { firstNav: "课程管理中心", secondNav: "课程核销管理" },
        activeName: 0,
        couponStatus: "2",
      };
    },
    methods: {
      handleClick() {},
      currentPageChange() {},
      handleClose(done) {
        done();
      },
    },
  };
  </script>
  
  <style lang="less">
  #consumerCoupon {
    background-color: #ffffff;
    padding: 0 20px;
    .an-box {
      display: flex;
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .an-p {
        color: #ff7e00;
        border-radius: 5px;
        border: #ff7e00 1px solid;
        padding: 4px 8px;
        margin-right: 20px;
        height: 24px;
        line-height: 24px;
        @media screen and(max-width:768px) {
          margin-bottom: 20px;
        }
      }
    }
  }
  .el-tabs__item {
    font-size: 16px !important;
  }
  .cell {
    text-align: center;
  }
  </style>
  