<template>
  <div class="container">
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="机构">
          <mechanismSelect :mecid.sync="mecid"></mechanismSelect>
        </el-form-item>
        <el-form-item label="电话">
          <el-input
            v-model="mobile"
            clearable
            placeholder="用户电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="cour_type"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查询时间">
          <el-date-picker
            v-model="date"
            type="daterange"
            align="right"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
        <el-button @click.enter="toCheck" type="info" style="float: right"
          >时间审核</el-button
        >
      </el-form>
    </el-row>
    <!-- 列表 -->
    <el-table
      :data="DataList"
      :fit="true"
      :cell-style="{ 'text-align': 'center' }"
      :header-cell-style="{ 'text-align': 'center' }"
      v-loading="is_loading"
    >
      <el-table-column
      type="index"
      width="50"></el-table-column>
      <el-table-column label="用户昵称" prop="nick_name">
        <template v-slot="{ row }">
            <link-to :to="{ name: 'userDetails', params: { id: row.user_id } }"> {{ row.nick_name }}</link-to>
          </template>
      </el-table-column>
      <el-table-column label="用户电话" prop="mobile"></el-table-column>
        <el-table-column label="上课人" prop="student_name">
           <template v-slot="{ row }">
            <p>
              {{row.student_name}}({{row.student_age}}岁)
            </p>
          </template>
        </el-table-column>
      <el-table-column label="课程" prop="title">
        <template v-slot="{ row }">
            <link-to :to="{ name: 'courseDetails', params: { id: row.master_set_price_id } }"> {{ row.title }}</link-to>
          </template>
      </el-table-column>
      <el-table-column
        label="结算金额"
        prop="settlement_cash"
      ></el-table-column>
      <el-table-column
        label="上课时间"
        prop="start_time"
        width="160px"
      ></el-table-column>
      <el-table-column
        label="下课时间"
        prop="end_time"
        width="160px"
      ></el-table-column>
      <el-table-column label="操作" width="250px">
        <template slot-scope="{ row }">
          <el-popconfirm
            v-if="status == 2 && row.status == 2"
            title="确定核销该课程吗？"
            @confirm="toHexiao(row)"
          >
            <el-button type="primary" slot="reference" size="small"
              >核销</el-button
            >
          </el-popconfirm>
          <el-button
            v-if="status != 3"
            type="info"
            @click="updateTime(row)"
            size="small"
            >修改时间</el-button
          >
          <el-button
            type="warning"
            slot="reference"
            @click="adjustCourse(row)"
            v-if="status == 2 && row.status == 2 && permissionList.includes('userAdjust')"
            size="small"
            style="margin-left: 0"
            >结算调整</el-button
          >
          <!-- {{ getPermList('1234')}} -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <paging-fy
      @currentPageChange="changeCurrentPage"
      :currentPage="currentPage"
      :total="Total"
    ></paging-fy>

    <!-- 课程调整 -->
    <el-dialog title="核销调整" :visible.sync="adjustShow">
      <el-form>
        <el-form-item label="调整金额" required>
          <el-input
            v-model="price"
            placeholder="调整金额"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="调整类型" required>
          <el-radio-group v-model="adjust_price_type">
            <el-radio :label="2">减少结算金额</el-radio>
            <el-radio :label="1">增加结算金额</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="调整原因" required>
          <el-radio-group v-model="adjust_type">
            <el-radio :label="1">调整</el-radio>
            <el-radio :label="2">抵消</el-radio>
          </el-radio-group>
        </el-form-item>
         <el-form-item label="调整说明" required>
            <el-input
            v-model="adj_remark"
            type="textarea"
            placeholder="调整说明"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="adjustSubmit"
            :disabled="adjusting"
            :key="adjusting"
            >提 交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改上课时间 -->
    <el-dialog title="修改时间" :visible.sync="timeshow" width="400px">
      <el-form>
        <el-form-item label="上课日期">
          <el-date-picker
            v-model="value"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-time-picker
            v-model="value1"
            :picker-options="{
              selectableRange: '07:00:00 - 23:30:00',
            }"
            value-format="HH:mm:ss"
            placeholder="任意时间点"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-time-picker
            v-model="value2"
            :picker-options="{
              selectableRange: value1 + ' - 23:59:00',
            }"
            value-format="HH:mm:ss"
            placeholder="任意时间点"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="说明">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="subTime">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
    
    <script>
import mechanismSelect from "@/components/select/mechanismSelect.vue";
export default {
  components: {
    mechanismSelect,
  },
  data() {
    return {
      adjust: {},
      adjustShow: false,
      adjusting: false,
      adjust: {},
      DataList: [],
      is_loading: true,
      currentPage: 1,
      timeshow: false,
      curCourse: {},
      time: [],
      Total: 0,
      date: "",
      value:'',
      value1: "",
      value2: "",
      before_time: "",
      start_time: "",
      end_time: "",
      remark:'',
      adj_remark:'',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 86400000;
        },
      },
      options: [
        {
          value: 2,
          label: "待上课",
        },
        {
          value: 3,
          label: "已完成",
        },
        {
          value: 10,
          label: "已过期",
        },
      ],
      mobile: "",
      mecid: "",
      price: "",
      status: 2,
      cour_type: 2,
      price: "",
      adjust_price_type: 2,
      adjust_type: 1,
      admin:JSON.parse(localStorage.getItem("managerInfo"))
    };
  },
  mounted() {
    this.getList();
  },
  computed:{
    permissionList(){
      return this.$store.state.permissionList
    }
  },
  methods: {
    //提交调整值
    adjustSubmit() {
      if (this.adjust_price_type == 1) {
        this.price = -this.price;
      }
      let url = "/user/userAdjust/add";
      let data = {
        appointment_id: this.curCourse.id,
        type: this.adjust_type,
        adjust_amount: this.price,
        remark:this.adj_remark || null,
        admin_id:this.admin.id,
        admin_name:this.admin.name
      };
      this.$axios({ url, data, method: "post" }).then((res) => {
        this.$message({ message: res.data.message });
        this.adjustShow = false;
      });
    },
    adjustCourse(row) {
      this.curCourse = row;
      this.adjustShow = true;
    },
    toCheck() {
      this.$router.push({ name: "TimeManage" });
    },

    updateTime(row) {
      console.log(row);
      this.timeshow = true;
      this.curCourse = row;
      this.value1 = row.start_time.split(' ')[1]
      this.value2 = row.end_time.split(' ')[1]

    },
    //时间选择
    timeCheck() {
      let now = new Date(this.start_time).getTime();
      let temp = new Date(this.end_time).getTime();
      if (now > temp) {
        return false;
      } else {
        return true;
      }
    },
    isdeadline(time) {
      let now = new Date().getTime();
      let temp = new Date(time).getTime();
      if (now > temp) {
        return false;
      } else {
        return true;
      }
    },
    //修改时间
    subTime() {
      if (this.value1 == this.value2) {
        this.$message({ message: "结束时间不能小于开始时间" });
        return;
      }
      if (!this.value || !this.value1 || !this.value2) {
        this.$message({ message: "请选择时间" });
        return;
      }
      let url = "user/masterNotice/insert";
      let data = {
        appointment_id: this.curCourse.id,
        type: "change_time",
        change_starttime: this.value + " " + this.value1,
        change_endtime: this.value + " " + this.value2,
        remark:this.remark
      };
      this.$axios({ url, data, method: "post" }).then((res) => {
        if(res.data.code == 0){
          this.$confirm('课程时间修改已提交')
           this.timeshow = false;
        }else{
          this.$message({ message: res.data.message });
        }
      });
    },
    //核销
    toHexiao(row) {
      console.log(row);
      let url = "user/userAppointment/checkAttendClass";
      let data = {
        id: row.id
      };
      if(row.master_type == 'mechanism_offline'){
        data.mechanism_id = row.mechanism_id
      }else{
        data.coach_id = row.coach_id
				data.course_type='need'
      }
      const loading = this.$loading({
          lock: true,
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      this.$axios({ url, data, method: "post" }).then((res) => {
         loading.close()
        this.$message({ message: res.data.message });
         this.getList();
      }).catch(err=>{
        loading.close()
      })
    },
    //课程
    getList() {
      this.is_loading = true;
      let data = {};
      let url = "/user/userAppointment/list/page";
      data = {
        currentPage: this.currentPage,
        pageSize: 10,
        offset: 8,
        status: this.cour_type,
      };
      if (this.cour_type == 2) {
        data.attend_class_start_time =
          new Date().Format("yyyy-MM-dd") + " 00:00:00";
        data.attend_class_end_time =
          new Date(new Date().setDate(31)).Format("yyyy-MM-dd") + " 23:59:59";
      }
      if (this.mobile) {
        data.mobile = this.mobile;
      }
      if (this.mecid) {
        data.mechanism_id = this.mecid;
      }
      if (this.date) {
        data.attend_class_start_time = this.date[0] + " 00:00:00";
        data.attend_class_end_time = this.date[1] + " 23:59:59";
      }
      this.$axios({ url, params: data, method: "get" }).then((res) => {
        this.is_loading = false;
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    search() {
      this.currentPage = 1;
      this.status = this.cour_type;
      this.getList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getList();
    },
  },
};
</script>
    
    <style lang="less">
</style>
    